<template>
   <div class="col-12 px-0 mb-1" ref="produto">
      <div class="card mb-0" :class="index % 2 == 1 ? 'invert' : ''">
         <div class="card-body p-12">
            <div class="row align-items-center">
               <div class="w-max-content pe-12 d-none d-lg-block">
                  <img :src="produto.banner == null ? '' : produto.banner" class="rounded" alt="icon" @error="imageError" height="50" />
               </div>
               <div class="col-md text-center text-md-start">
                  <div class="row align-items-center">
                     <div class="col-12">
                        <h1 class="font-15 mb-0 limitador-1 text-uppercase">{{ produto.nome }}</h1>
                        <p class="font-10 mb-0 mb-lg-2 limitador-1 text-secondary">
                           <span><i class="far fa-hashtag font-9 me-1"></i>{{ produto.id }}</span>
                           <span class="ms-3" :class="produto.clubeDesconto ? 'd-none d-xl-inline' : ''">
                              <i class="far fa-tags font-9 me-1"></i>
                              <span>{{ produto.familia == null || String(produto.familia).trim().length == 0 ? 'Sem família' : produto.familia }}</span>
                           </span>
                           <span class="ms-3" v-if="produto.clubeDesconto">
                              <i class="far fa-info-circle font-9 me-1"></i><span>Produto faz parte de clube de desconto</span>
                           </span>
                        </p>
                     </div>
                     <div class="col d-none d-lg-block">
                        <p class="font-12 mb-0 limitador-1">
                           <i class="far fa-barcode color-theme font-10 me-1"></i><strong class="subtitle me-1 d-none d-xl-inline"> Código(s):</strong>
                           <span> {{ produto.codigos.filter(e => e.ean != null).length == 0 ? 'Sem código' : String(produto.codigos.filter(e => e.ean != null).map(e => e.ean)).replace(/,/g, ", ") }}</span>
                        </p>
                     </div>
                     <div class="col-5 d-none d-xl-block">
                        <p class="font-12 mb-0 limitador-1">
                           <i class="far fa-bookmark color-theme font-10 me-1"></i><strong class="subtitle me-1 d-none d-xl-inline"> Marca:</strong>
                           <span> {{ produto.marca == null || String(produto.marca).trim().length == 0 ? 'Sem marca' : produto.marca }}</span>
                        </p>
                     </div>
                  </div>
               </div>
               <div class="w-max-content text-center d-none d-xxl-block" v-if="config.custo">
                  <label><i class="far fa-dollar-sign color-theme font-9 me-1"></i> Custo</label>
                  <input type="text" class="form-control input-sm wpx-115" v-model="editado.custo" :disabled="!dadosUsuario.usuarioPermissoes.includes('Produtos | Editar preços')" />
               </div>
               <div class="w-max-content text-center mx-auto mx-md-0 mt-2 mt-md-0" v-if="config.precoSite && dadosUsuario.usuarioModulos.includes('Ecommerce')">
                  <label><i class="far fa-desktop color-theme font-9 me-1"></i> Ecommerce</label>
                  <input type="text" class="form-control input-sm wpx-115" v-model="editado.precoSite" :disabled="!dadosUsuario.usuarioPermissoes.includes('Produtos | Editar preços')" />
               </div>
               <div class="w-max-content text-center mx-auto mx-md-0 mt-2 mt-md-0" v-if="config.preco">
                  <label><i class="far fa-dollar-sign color-theme font-9 me-1"></i> Preço</label>
                  <input type="text" class="form-control input-sm wpx-115" v-model="editado.preco" :disabled="!dadosUsuario.usuarioPermissoes.includes('Produtos | Editar preços')" />
               </div>
               <div class="w-max-content text-center font-16 mx-auto me-md-0 ms-md-3 mt-2 mt-md-0">
                  <div class="btn-icone color-theme">
                     <span @click="historico"><i class="far fa-history"></i><small>Histórico</small></span>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import { mapState } from 'vuex'

export default {
   name: 'Produto',
   props: ['produto', 'index', 'config'],
   data : function () {
      return {
         editado: this.config.precosEditados.find(p => p.id == this.produto.id) != null ? this.config.precosEditados.find(p => p.id == this.produto.id) : {
            'id': this.produto.id, 
            'nome': this.produto.nome, 
            'codigos': this.produto.codigos.length == 0 ? 'Sem código' : String(this.produto.codigos.map(sub => sub.ean)).replace(/,/g, ", "), 
            'idLoja': this.produto.idLoja, 
            'preco': this.produto.preco == null ? "0.00" : parseFloat(this.produto.preco).toFixed(2), 
            'precoSite': this.produto.precoSite == null ? "0.00" : parseFloat(this.produto.precoSite).toFixed(2), 
            'custo': this.produto.custo == null ? "0.00" : parseFloat(this.produto.custo).toFixed(2),
            'aplicarTodas': this.config.aplicarTodas
         }
      }
   },
   computed: {
      ... mapState({
         dadosUsuario: state => state.dadosUsuario,
         urlRest: state => state.urlRest
      })
   },
   watch: {
      'editado.preco': function (newVal, oldVal) {
         this.$store.dispatch('formatValue', {'valor': newVal, 'digitos': 2}).then((valor) => {
            this.editado.preco = valor
            if (parseFloat(oldVal) != parseFloat(newVal) && this.produto.preco != valor) this.$emit('addTemp', this.editado)
         })
      },
      'editado.precoSite': function (newVal, oldVal) {
         this.$store.dispatch('formatValue', {'valor': newVal, 'digitos': 2}).then((valor) => {
            this.editado.precoSite = valor
            if (parseFloat(oldVal) != parseFloat(newVal) && this.produto.precoSite != valor) this.$emit('addTemp', this.editado)
         })
      },
      'editado.custo': function (newVal, oldVal) {
         this.$store.dispatch('formatValue', {'valor': newVal, 'digitos': 2}).then((valor) => {
            this.editado.custo = valor
            if (parseFloat(oldVal) != parseFloat(newVal) && this.produto.custo != valor) this.$emit('addTemp', this.editado)
         })
      }
   },
   methods: {
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      },
      historico : function () {
         this.$emit('historico', this.produto)
      }
   }
}

</script>