<template>
	<div class="row">
		<div class="col-12" v-if="telaAjuda[$route.path.substring(1, $route.path.lastIndexOf('/'))]">
         <div class="alert alert-secondary alert-dismissible fade show mb-2" role="alert">
            <strong>{{ $route.name != null ? $route.name.replace(/_/g, " ").replace(/-/g, "/") : '' }}</strong><i class="far fa-arrow-alt-right font-10 mx-2"></i>
            <span>Defina os preços dos produtos para o sistema de ecommerce e/ou caixa, confira o histórico de preços de venda e custo. Clique sobre um produto para alterar.</span>
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" @click="$store.dispatch('toggleTelaAjuda', $route.path.substring(1, $route.path.lastIndexOf('/')))"></button>
         </div>
      </div>

      <div id="search-nav" class="col-12 d-block btn-group dropup">
         <button type="button" class="btn btn-primary dropdown-toggle d-block d-lg-none" data-bs-toggle="dropdown" data-bs-auto-close="false" aria-expanded="false">
            <i class="far fa-search font-17 mx-auto"></i><i class="fal fa-times font-17 mx-auto"></i>
         </button>
			<pesquisaPreco class="dropdown-menu dropdown-menu-left" ref="pesquisaPreco" @setRetorno="setRetornoPesquisa($event)" />
      </div>

      <div class="col-12" v-if="pesquisa.resultado.length > 0">
			<div class="card mb-0">
				<div class="card-body p-12">
					<div class="row">
						<div class="col mb-2 align-self-center">
							<div class="limitador-1 mb-1"><i class="fas fa-angle-right color-theme me-1"></i> {{ pesquisa.resultado.length == 100 ? 'Limitado a ': '' }} {{ pesquisa.resultado.length }} resultado(s)</div>
						</div>
						<div class="w-max-content pe-3 mb-2 align-self-center d-none d-xxl-block">
							<div class="form-check form-switch">
								<input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" @click="config.custo = !config.custo" :checked="config.custo">
								<label class="form-check-label m-0" for="flexSwitchCheckDefault">Custo</label>
							</div>
						</div>
						<div class="w-max-content pe-3 mb-2 align-self-center d-none d-xxl-block" v-if="dadosUsuario.usuarioModulos.includes('Ecommerce')">
							<div class="form-check form-switch">
								<input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault2" @click="config.precoSite = !config.precoSite" :checked="config.precoSite">
								<label class="form-check-label m-0" for="flexSwitchCheckDefault2">Ecommerce</label>
							</div>
						</div>
						<div class="w-max-content pe-3 mb-2 align-self-center d-none d-xxl-block">
							<div class="form-check form-switch">
								<input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault3" @click="config.preco = !config.preco" :checked="config.preco">
								<label class="form-check-label m-0" for="flexSwitchCheckDefault3">Preço</label>
							</div>
						</div>
						<div class="w-max-content pe-3 mb-2 align-self-center d-none d-xxl-block">
							<div class="form-check form-switch">
								<input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault4" @click="config.aplicarTodas = !config.aplicarTodas" :checked="config.aplicarTodas">
								<label class="form-check-label m-0" for="flexSwitchCheckDefault4">Alterar todas lojas</label>
							</div>
						</div>
						<div class="w-max-content mb-2" v-if="config.precosEditados.length > 0">
							<a data-bs-toggle="offcanvas" href="#offcanvasPrecos" role="button" aria-controls="offcanvasPrecos" class="badge badge-default bg-theme pulse-effect mb-1">
								<i class="far fa-dollar-sign font-10 me-1"></i> Preços alterados
							</a>
						</div>
						<div class="w-max-content mb-2">
							<a href="javascript:;" class="badge badge-default bg-theme mb-1" @click="exportar"><i class="far fa-share font-10 me-1"></i> Exportar</a>
						</div>

						<produto v-for="(produto, index) in pesquisa.resultado" :key="index" :produto="produto" :index="index" :config="config" 
							@addTemp="addTemp($event)" @historico="getHistorico($event)" />
					</div>
				</div>
			</div>
      </div>

      <!-- modalHistoricoPreco -->
      <div class="modal fade" id="modalHistoricoPreco" tabindex="-1" aria-labelledby="modalHistoricoPrecoLabel" aria-hidden="true">
         <div class="modal-dialog modal-md h-100 modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content h-100">
               <div class="modal-header">
                  <h5 class="modal-title" id="modalHistoricoPrecoLabel">Histórico do produto ({{ modal.nome }})</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div class="modal-body">
						<div class="card mb-2">
							<div class="card-body p-12">
								<ul class="nav nav-pills" id="pills-tab" role="tablist">
									<li class="nav-item col" role="presentation">
										<button class="nav-link m-0 active" id="pills-preco-tab" data-bs-toggle="pill" data-bs-target="#pills-preco" type="button" role="tab" 
											aria-controls="pills-preco" aria-selected="true">
											<i class="far fa-home font-12"></i><span class="ms-1 d-none d-sm-inline"> Preços</span>
										</button>
									</li>
									<li class="nav-item col" role="presentation">
										<button class="nav-link m-0" id="pills-custo-tab" data-bs-toggle="pill" data-bs-target="#pills-custo" type="button" role="tab" 
											aria-controls="pills-custo" aria-selected="false">
											<i class="far fa-percentage font-13"></i><span class="ms-1 d-none d-sm-inline"> Custos</span>
										</button>
									</li>
									<li class="nav-item col" role="presentation" v-if="dadosUsuario.usuarioModulos.includes('Ecommerce')">
										<button class="nav-link m-0" id="pills-ecommerce-tab" data-bs-toggle="pill" data-bs-target="#pills-ecommerce" type="button" role="tab" 
											aria-controls="pills-ecommerce" aria-selected="false">
											<i class="far fa-percentage font-13"></i><span class="ms-1 d-none d-sm-inline"> Preços ecommerce</span>
										</button>
									</li>
								</ul>
							</div>
						</div>

						<div class="tab-content" id="pills-tabContent">
							<div class="tab-pane fade show active" id="pills-preco" role="tabpanel" aria-labelledby="pills-preco-tab">
								<div class="row">
									<historico v-for="(historico, index) in modal.precos" :key="index" :historico="historico" :index="index" />
									<div class="col-12 my-5 text-center" v-if="modal.precos.length == 0">
										<i class="fal fa-align-slash font-60 text-secondary opacity-50 d-block mb-3"></i>
										<h4 class="font-12 mt-4 mb-0 text-secondary text-uppercase">Nenhum historico encontrado</h4>
									</div>
								</div>
							</div>
							
							<div class="tab-pane fade" id="pills-custo" role="tabpanel" aria-labelledby="pills-custo-tab">
								<div class="row">
									<historico v-for="(historico, index) in modal.custos" :key="index" :historico="historico" :index="index" />
									<div class="col-12 my-5 text-center" v-if="modal.custos.length == 0">
										<i class="fal fa-align-slash font-60 text-secondary opacity-50 d-block mb-3"></i>
										<h4 class="font-12 mt-4 mb-0 text-secondary text-uppercase">Nenhum historico encontrado</h4>
									</div>
								</div>
							</div>
							
							<div class="tab-pane fade" id="pills-ecommerce" role="tabpanel" aria-labelledby="pills-ecommerce-tab" v-if="dadosUsuario.usuarioModulos.includes('Ecommerce')">
								<div class="row">
									<historico v-for="(historico, index) in modal.precosSite" :key="index" :historico="historico" :index="index" />
									<div class="col-12 my-5 text-center" v-if="modal.precosSite.length == 0">
										<i class="fal fa-align-slash font-60 text-secondary opacity-50 d-block mb-3"></i>
										<h4 class="font-12 mt-4 mb-0 text-secondary text-uppercase">Nenhum historico encontrado</h4>
									</div>
								</div>
							</div>
						</div>
               </div>
               <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
               </div>
            </div>
         </div>
      </div>

		<!-- offcanvasPrecos -->
		<div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasPrecos" aria-labelledby="offcanvasPrecosLabel">
			<div class="offcanvas-header">
				<h5 id="offcanvasRightLabel">Preços alterados</h5>
				<button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
			</div>
			<div class="offcanvas-body">
				<editado v-for="(editado, index) in config.precosEditados" :key="index" :editado="editado" :index="index" @remover="removerTemp($event)" />

				<div class="col-12 my-5 text-center" v-if="config.precosEditados.length == 0">
					<i class="fal fa-box-open font-60 text-secondary opacity-50 d-block mb-3"></i>
					<h4 class="font-12 mt-4 mb-0 text-secondary text-uppercase">Nenhum produto encontrado</h4>
				</div>
			</div>
			<div class="offcanvas-footer">
				<button type="button" class="btn btn-primary" @click="salvarPrecos" v-if="config.precosEditados.length > 0">Salvar</button>
				<button type="button" class="btn btn-secondary" data-bs-dismiss="offcanvas">Fechar</button>
			</div>
		</div>
   </div>
</template>

<script>

import $ from 'jquery'
import { mapState } from 'vuex'
import historico from '@/components/modelos/Historico.vue'
import editado from '@/components/produtos/precos/Editado.vue'
import produto from '@/components/produtos/precos/Produto.vue'
import pesquisaPreco from '@/components/modelos/PesquisaPreco.vue'

export default {
	name: 'Precos',
	data: function () {
		return {
			pesquisa: {'categoria': null, 'subcategoria': null, 'nome': '', 'marca': null, 'loja': null, 'resultado': []},
			listaSubcategorias: [],
			modal: {'nome': null, 'precos': [], 'custos': [], 'precosSite': []},
			config: {'preco': true, 'precoSite': window.innerWidth < 1400, 'custo': window.innerWidth >= 1400, 'aplicarTodas': false, 'precosEditados': []}
		}
	},
	computed: {
      ... mapState({
         listaCategorias: state => state.listaCategorias,
         dadosUsuario: state => state.dadosUsuario,
         listaMarcas: state => state.listaMarcas,
			telaAjuda: state => state.telaAjuda,
         urlRest: state => state.urlRest,
			tabs: state => state.tabs
      })
   },
	watch: {
		$data: {
			handler: function(value) {
				this.$store.dispatch('attTabData', JSON.parse(JSON.stringify({'id': this.$route.params.idTab, 'data': value})))
			},
			deep: true
		},
		'$route.params.idTab': function (value) {
         Object.assign(this.$data, this.tabs[value].data)
      }
	},
	components: {
		produto, historico, pesquisaPreco, editado
	},
	methods: {
		exportar : function () {
			this.$store.dispatch('exportarExcel', {
				'nome': 'Preços',
				'resultado': this.pesquisa.resultado, 
				'campos':  ['id', 'nome', 'marca', 'categoria', 'subcategoria', 'familia', 'clubeDesconto', 'custo', 'precoSite', 'preco'], 
				'lista': {'nome': 'codigos', 'campos': ['id', 'ean', 'codigoProduto', 'observacao']}
			})
		},
		imageError : function (e) {
         this.$store.dispatch('imageError', e)
      },
		setRetornoPesquisa : function (retorno) {
			this.pesquisa = this.$options.data.call(this).pesquisa;
			this.config.precosEditados = [];
			setTimeout(() => this.pesquisa = retorno, 50);
		},
		addTemp : function (editado) {
			let index = this.config.precosEditados.findIndex(produto => produto.id == editado.id && produto.idLoja == editado.idLoja)
			
			if (index == -1) {
				this.config.precosEditados.push(editado)
			} else {
				this.$set(this.config.precosEditados, index, editado)
			}
		},
		removerTemp : function (index) {
			this.config.precosEditados.splice(index, 1)
		},
		getHistorico : function (produto) {
			this.$store.dispatch('alternarTelaCarregamento', true)
			Object.assign(this.$data.modal, this.$options.data.apply(this).modal)

			this.$axios({
				method: 'post',
				url: this.urlRest +'produtos/getHistoricoPrecoCusto',
				headers: {'Content-Type': 'application/json'},
				data: {'idProduto': produto.id, 'idLoja': produto.idLoja}

			}).then(response => {
				this.modal = {
					'nome': produto.nome, 
					'precos': response.data.precos, 
					'custos': response.data.custos, 
					'precosSite': response.data.precosSite
				}
			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				}
			}).finally(() => {
				$('#modalHistoricoPreco').modal('show')
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		},
		salvarPrecos : function () {
			this.$store.dispatch('alternarTelaCarregamento', true)

			this.config.precosEditados.forEach(produto => {
				produto.aplicarTodas = this.config.aplicarTodas
				produto.preco = (produto.preco == null ? null : String(produto.preco).replace(/,/g, "."))
				produto.precoSite = (produto.precoSite == null ? null : String(produto.precoSite).replace(/,/g, "."))
				produto.custo = (produto.custo == null ? null : String(produto.custo).replace(/,/g, "."))
			});
			
			this.$axios({
				method: 'post',
				url: this.urlRest +'produtos/savePrecos',
				headers: {'Content-Type': 'application/json'},
				data: this.config.precosEditados

			}).then(() => {
				$("#offcanvasPrecos").offcanvas("hide")
				this.$refs.pesquisaPreco.buscarPrecos()
				
				this.$toast.fire({
					icon: 'success',
					title: 'Preços salvos!'
				});
			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		}
	},
	mounted() {
      this.$store.dispatch('getCategorias')
		this.$store.dispatch('getMarcas')
		Object.assign(this.$data, this.tabs[this.$route.params.idTab].data)

		$(window).resize(() => {
			if (window.innerWidth < 1400) {
				this.config.preco = true
				this.config.precoSite = true
				this.config.custo = false
			}
      });
	}
}

</script>